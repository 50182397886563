import "./App.css";
import React, { useRef, useState } from "react";
import Geolocation from 'react-native-geolocation-service';
import { QrReader } from "react-qr-reader";

function App() {
  const [data, setData] = useState("No result");
  const [postContent, setPostContent] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const locRef = useRef(null);
  const hasLocationPermission = true;

  const getUserLocation = () => {
    if (hasLocationPermission) {
      Geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          setPostContent(`${latitude}:${longitude}`);
        },
        (error) => {
          // display an error if we cant get the users position
          console.error("Error getting user location:", error);
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
      );
    } else {
      // display an error if not supported
      console.error("Geolocation is not supported by this browser.");
    }
  };

  function copyText(e) {
    locRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    alert("Copied to clipboard!");
  } 

  return (
    <div className="App">
      <QrReader
        onResult={(result, error) => {
          if (!!result){
            setData(result?.text);
            getUserLocation();
          }
        }}
        style={{ width: "100%" }}
        constraints={{ facingMode: "environment" }}
      />
      {userLocation && (
        <div>
          <p>{data}</p>
          <h2>User Location</h2>
          <textarea 
          ref={locRef} 
          value={postContent}
          readOnly={true}
          />
          <br></br>
          <button onClick={copyText}>Copy to clipboard</button>
        </div>
        )}
    </div>
  );
}

export default App;
